






























































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Solution extends Vue {
	private parentData = {
		titleName: "免费工具",
		dec:
			"智慧消防管理工具， 智慧消防运营管理平台，智慧消防客服系统，免费申请使用",
		bannerImg: require("../../assets/images/banner3.jpg"),
		img: require("../../assets/images/banner_icon.png"),
		imgWidth: "283px",
		imgHeight: "283px"
	};

	private created() {
		this.$emit("getChildrenData", this.parentData);
	}
}
